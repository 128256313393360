import { inject } from 'vue';
import type CabinetApi from '@/api-clients/cabinetApi/cabinet';

export default function useCabinetApi() {
  const apiClient = inject<{ cabinet: CabinetApi }>('apiClient');

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    apiClient: apiClient.cabinet,
  };
}
